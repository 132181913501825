/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: var(--ion-color-ESNdarkBlue);
  --ion-color-primary-rgb: var(--ion-color-ESNdarkBlue-rgb);
  --ion-color-primary-contrast: var(--ion-color-ESNdarkBlue-contrast);
  --ion-color-primary-contrast-rgb: var(--ion-color-ESNdarkBlue-contrast-rgb);
  --ion-color-primary-shade: var(--ion-color-ESNdarkBlue-shade);
  --ion-color-primary-tint: var(--ion-color-ESNdarkBlue-tint);

  --ion-color-secondary: #ffc300;
  --ion-color-secondary-rgb: 255, 195, 0;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #e6b000;
  --ion-color-secondary-tint: #ffc919;

  --ion-color-tertiary: var(--ion-color-ESNcyan);
  --ion-color-tertiary-rgb: var(--ion-color-ESNcyan-rgb);
  --ion-color-tertiary-contrast: var(--ion-color-ESNcyan-contrast);
  --ion-color-tertiary-contrast-rgb: var(--ion-color-ESNcyan-contrast-rgb);
  --ion-color-tertiary-shade: var(--ion-color-ESNcyan-shade);
  --ion-color-tertiary-tint: var(--ion-color-ESNcyan-tint);

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-ideaToolbar: #222428;
  --ion-color-ideaToolbar-rgb: 34, 36, 40;
  --ion-color-ideaToolbar-contrast: #ffffff;
  --ion-color-ideaToolbar-contrast-rgb: 255, 255, 255;
  --ion-color-ideaToolbar-shade: #202226;
  --ion-color-ideaToolbar-tint: #2d2f33;

  --ion-color-white: white;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #40474c;
  --ion-color-white-contrast-rgb: 64, 71, 76;
  --ion-color-white-shade: #f4f4f4;
  --ion-color-white-tint: #f3f6f9;

  --ion-color-ESNcyan: #00aeef;
  --ion-color-ESNcyan-rgb: 0, 174, 239;
  --ion-color-ESNcyan-contrast: #ffffff;
  --ion-color-ESNcyan-contrast-rgb: 255, 255, 255;
  --ion-color-ESNcyan-shade: #0084b6;
  --ion-color-ESNcyan-tint: #3dc1f3;

  --ion-color-ESNgreen: #7ac143;
  --ion-color-ESNgreen-rgb: 122, 193, 67;
  --ion-color-ESNgreen-contrast: #ffffff;
  --ion-color-ESNgreen-contrast-rgb: 255, 255, 255;
  --ion-color-ESNgreen-shade: #5d9333;
  --ion-color-ESNgreen-tint: #9ad070;

  --ion-color-ESNpink: #ec008c;
  --ion-color-ESNpink-rgb: 236, 0, 140;
  --ion-color-ESNpink-contrast: #ffffff;
  --ion-color-ESNpink-contrast-rgb: 255, 255, 255;
  --ion-color-ESNpink-shade: #b3006a;
  --ion-color-ESNpink-tint: #f13da8;

  --ion-color-ESNorange: #f47b20;
  --ion-color-ESNorange-rgb: 244, 123, 32;
  --ion-color-ESNorange-contrast: #ffffff;
  --ion-color-ESNorange-contrast-rgb: 255, 255, 255;
  --ion-color-ESNorange-shade: #b95d18;
  --ion-color-ESNorange-tint: #f79b56;

  --ion-color-ESNdarkBlue: #2e3192;
  --ion-color-ESNdarkBlue-rgb: 46, 49, 146;
  --ion-color-ESNdarkBlue-contrast: #ffffff;
  --ion-color-ESNdarkBlue-contrast-rgb: 255, 255, 255;
  --ion-color-ESNdarkBlue-shade: #23256f;
  --ion-color-ESNdarkBlue-tint: #6062ac;

  --ion-color-step-50: #f2f2f2;
  --ion-color-step-100: #e6e6e6;
  --ion-color-step-150: #d9d9d9;
  --ion-color-step-200: #cccccc;
  --ion-color-step-250: #bfbfbf;
  --ion-color-step-300: #b3b3b3;
  --ion-color-step-350: #a6a6a6;
  --ion-color-step-400: #999999;
  --ion-color-step-450: #8c8c8c;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #737373;
  --ion-color-step-600: #666666;
  --ion-color-step-650: #595959;
  --ion-color-step-700: #4d4d4d;
  --ion-color-step-750: #404040;
  --ion-color-step-800: #333333;
  --ion-color-step-850: #262626;
  --ion-color-step-900: #191919;
  --ion-color-step-950: #0d0d0d;

  --ion-background-color: var(--ion-color-white-shade);

  --ion-border-color: #eee;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-ideaToolbar {
  --ion-color-base: var(--ion-color-ideaToolbar);
  --ion-color-base-rgb: var(--ion-color-ideaToolbar-rgb);
  --ion-color-contrast: var(--ion-color-ideaToolbar-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ideaToolbar-contrast-rgb);
  --ion-color-shade: var(--ion-color-ideaToolbar-shade);
  --ion-color-tint: var(--ion-color-ideaToolbar-tint);
}

.ion-color-ESNcyan {
  --ion-color-base: var(--ion-color-ESNcyan) !important;
  --ion-color-base-rgb: var(--ion-color-ESNcyan-rgb) !important;
  --ion-color-contrast: var(--ion-color-ESNcyan-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-ESNcyan-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-ESNcyan-shade) !important;
  --ion-color-tint: var(--ion-color-ESNcyan-tint) !important;
}

.ion-color-ESNgreen {
  --ion-color-base: var(--ion-color-ESNgreen) !important;
  --ion-color-base-rgb: var(--ion-color-ESNgreen-rgb) !important;
  --ion-color-contrast: var(--ion-color-ESNgreen-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-ESNgreen-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-ESNgreen-shade) !important;
  --ion-color-tint: var(--ion-color-ESNgreen-tint) !important;
}

.ion-color-ESNpink {
  --ion-color-base: var(--ion-color-ESNpink) !important;
  --ion-color-base-rgb: var(--ion-color-ESNpink-rgb) !important;
  --ion-color-contrast: var(--ion-color-ESNpink-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-ESNpink-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-ESNpink-shade) !important;
  --ion-color-tint: var(--ion-color-ESNpink-tint) !important;
}

.ion-color-ESNorange {
  --ion-color-base: var(--ion-color-ESNorange) !important;
  --ion-color-base-rgb: var(--ion-color-ESNorange-rgb) !important;
  --ion-color-contrast: var(--ion-color-ESNorange-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-ESNorange-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-ESNorange-shade) !important;
  --ion-color-tint: var(--ion-color-ESNorange-tint) !important;
}

.ion-color-ESNdarkBlue {
  --ion-color-base: var(--ion-color-ESNdarkBlue) !important;
  --ion-color-base-rgb: var(--ion-color-ESNdarkBlue-rgb) !important;
  --ion-color-contrast: var(--ion-color-ESNdarkBlue-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-ESNdarkBlue-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-ESNdarkBlue-shade) !important;
  --ion-color-tint: var(--ion-color-ESNdarkBlue-tint) !important;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: var(--ion-color-ESNcyan);
    --ion-color-primary-rgb: var(--ion-color-ESNcyan-rgb);
    --ion-color-primary-contrast: var(--ion-color-ESNcyan-contrast);
    --ion-color-primary-contrast-rgb: var(--ion-color-ESNcyan-contrast-rgb);
    --ion-color-primary-shade: var(--ion-color-ESNcyan-shade);
    --ion-color-primary-tint: var(--ion-color-ESNcyan-tint);

    --ion-color-tertiary: var(--ion-color-ESNdarkBlue);
    --ion-color-tertiary-rgb: var(--ion-color-ESNdarkBlue-rgb);
    --ion-color-tertiary-contrast: var(--ion-color-ESNdarkBlue-contrast);
    --ion-color-tertiary-contrast-rgb: var(--ion-color-ESNdarkBlue-contrast-rgb);
    --ion-color-tertiary-shade: var(--ion-color-ESNdarkBlue-shade);
    --ion-color-tertiary-tint: var(--ion-color-ESNdarkBlue-tint);

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    --ion-color-white: #1e1e1e;
    --ion-color-white-rgb: 30, 30, 30;
    --ion-color-white-contrast: #ccc;
    --ion-color-white-contrast-rgb: 204, 204, 204;
    --ion-color-white-shade: #1a1a1a;
    --ion-color-white-tint: #202020;

    --ion-color-ideaToolbar: #121212;
    --ion-color-ideaToolbar-rgb: 18, 18, 18;
    --ion-color-ideaToolbar-contrast: #ffffff;
    --ion-color-ideaToolbar-contrast-rgb: 255, 255, 255;
    --ion-color-ideaToolbar-shade: #080808;
    --ion-color-ideaToolbar-tint: #222;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;

    --ion-border-color: #222222;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}
