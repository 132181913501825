*:not(ion-icon) {
  font-family: 'Poppins', sans-serif !important;
  letter-spacing: normal;
}

a {
  text-decoration: underline var(--ion-color-step-100);
  color: var(--ion-color-step-600);
}
a:hover {
  text-decoration: underline var(--ion-color-step-600);
}

/* auth page */
[data-indicator-position='top'] > .amplify-tabs-item {
  border-top-width: 5px;
  margin-top: -5px;
}
[data-amplify-authenticator] [data-amplify-container] {
  margin: 0 auto;
}
label.amplify-label.sr-only {
  display: none !important;
}
[data-amplify-theme] {
  --amplify-colors-brand-primary-10: rgba(var(--ion-color-ESNdarkBlue-rgb), 0.1) !important;
  --amplify-colors-brand-primary-20: rgba(var(--ion-color-ESNdarkBlue-rgb), 0.2) !important;
  --amplify-colors-brand-primary-30: rgba(var(--ion-color-ESNdarkBlue-rgb), 0.3) !important;
  --amplify-colors-brand-primary-40: rgba(var(--ion-color-ESNdarkBlue-rgb), 0.4) !important;
  --amplify-colors-brand-primary-50: rgba(var(--ion-color-ESNdarkBlue-rgb), 0.5) !important;
  --amplify-colors-brand-primary-60: rgba(var(--ion-color-ESNdarkBlue-rgb), 0.6) !important;
  --amplify-colors-brand-primary-70: rgba(var(--ion-color-ESNdarkBlue-rgb), 0.7) !important;
  --amplify-colors-brand-primary-80: rgba(var(--ion-color-ESNdarkBlue-rgb), 1) !important;
  --amplify-colors-brand-primary-90: rgba(var(--ion-color-ESNdarkBlue-rgb), 0.9) !important;
  --amplify-colors-brand-primary-100: rgba(var(--ion-color-ESNdarkBlue-rgb), 0.9) !important;
}

.flexBox {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

ion-toolbar ion-title {
  text-align: center !important;
  padding: 0 !important;
}

ion-tab-button.tab-selected ion-label {
  font-weight: bold !important;
}

ion-list {
  background: transparent !important;
}
ion-list-header {
  padding-left: 0;
}
ion-item ion-label p {
  color: var(--ion-color-step-600) !important;
}
ion-item ion-label p a {
  color: var(--ion-color-step-450) !important;
}
ion-item-divider {
  border-bottom: none !important;
  margin-top: 10px !important;
  font-weight: 500 !important;
  color: var(--ion-color-step-650) !important;
}

ion-searchbar {
  --box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px !important;
}

ion-card {
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.05) !important;
}

ion-button.button-solid {
  --box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

ion-segment-button {
  text-transform: none !important;
}

ion-label h1 {
  font-size: 1.6rem !important;
}
ion-label h2 {
  font-size: 1.4rem !important;
}
ion-label h3 {
  font-size: 1.2rem !important;
}

.maplibregl-popup-content {
  background: var(--ion-color-white);
}

.fieldHasError {
  border-bottom: 2px solid var(--ion-color-danger);
}

ion-grid.contentGrid {
  max-width: 1200px;
  margin: 0 auto;
}

.rc-md-editor {
  background-color: var(--ion-color-white);
  border: none;
  border-bottom: 1px solid var(--ion-border-color);
}
.rc-md-editor .rc-md-navigation,
.rc-md-editor .editor-container .sec-md .input {
  background: transparent;
  border-bottom-color: var(--ion-border-color);
  color: var(--ion-text-color);
}
.rc-md-editor .rc-md-navigation {
  padding: 0 16px;
}
.rc-md-editor .rc-md-navigation .button-wrap .button {
  color: var(--ion-color-white-contrast);
}
.rc-md-editor .rc-md-navigation .button-wrap .button:hover {
  color: rgba(var(--ion-color-dark-rgb, 0.6));
}
.custom-html-style {
  color: var(--ion-text-color);
}

.divDescription {
  padding: 20px;
  background-color: var(--ion-color-light);
}
.divDescription p {
  margin-bottom: 16px;
  text-align: justify;
}

.widePopover {
  --min-width: 300px;
}
@media only screen and (min-width: 350px) {
  .widePopover {
    --min-width: 350px;
  }
}
@media only screen and (min-width: 400px) {
  .widePopover {
    --min-width: 400px;
  }
}

.tappable {
  cursor: pointer !important;
}

ion-img.inGallery {
  --box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}
ion-img.inGallery::part(image) {
  border-radius: 2px;
}

.forceMargins p {
  margin: 15px 10px !important;
}
